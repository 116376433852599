<template>
  <div>
    <!-- <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"
                style="padding-top:0px;background: none;border: none;"/> -->
    <div class="p-col-12" v-if="userRoleCode!=='ncz'&&(!list||list.length===0)" style="height: 80vh;display: flex;align-items: center;justify-content: center">
      <div style="font-size: 18px;width: 200px;text-align: center;color: #495057">
        <img style="width: 200px" src="../../../assets/images/empty.png">
        暂无数据...
      </div>
    </div>
    <div v-else class="card p-grid p-m-0">
      <div class="p-col-12 p-d-flex p-justify-end p-align-center">
        <div>项目阶段：</div>
        <Dropdown @change="getList('search')" v-model="state" class="p-mr-2" :options="[{label:'全部',value:-1},{label:'规划中',value:0},{label:'已导入',value:1}]" option-label="label" option-value="value"></Dropdown>
        <!-- <Button >查询</Button> -->
      </div>
      <div class="p-col-4" style="color: #24bd7f" v-if="userRoleCode==='ncz'">
        <div class="cardItem p-d-flex p-justify-center p-align-center" style="cursor: pointer" @click="createPlan">
          <div style="text-align: center">
            <!--            <i class="pi pi-plus" style="font-size: 4rem"></i>-->
            <img src="@/assets/images/jiahao.png">
            <p>创建新规划</p>
          </div>
        </div>
      </div>

      <div class="p-col-4" v-for="item in list" v-if="!loading">
        <div class="cardItem">
          <div class="top">
            <span style="font-weight: 700;">{{ item.name }}</span>
            <span style="padding: 3px 8px 3px 8px;border-radius: 30px;" :style="{'background-color':item.state===0?'#f9dee1' : item.state===1 ? '#f9dee1':item.state===2? '#f9dee1': item.state===3? '#fef7dd':item.state===4? '#d4efe1':item.state===5?'#e7e7e7':null,
                          'color' :item.state===0?'#e53a4c' : item.state===1 ? '#e53a4c':item.state===2? '#e53a4c': item.state===3? '#f3aa35':item.state===4? '#3d974f':item.state===5?'#454545':null,
            }">
              {{
                item.state === 0 ? '创建中' : (item.state === 1 ? '待分配专家' : (item.state === 2 ? '未建议' : (item.state === 3
                    ? '专家建议中'
                    : (item.state === 4 ? '专家完成建议' : (item.state === 5 ? '已导入项目' : null)))))
              }}
            </span>
          </div>
          <div class="content">
            <div style="width:50%">
              <show-map :paths="item.areaMap" :area0="item.area" :center0="[item.positionLon,item.positionLat]" :update-but="false"></show-map>
            </div>
            <div class="right">
              <p>项目面积：<span v-tooltip.top="item.area+item.areaUnit">{{ item.area }}{{ item.areaUnit }}</span></p>
              <p>整体条件：<span v-tooltip="item.cond">{{ item.cond }}</span></p>
              <p>灌溉条件：<span v-tooltip="item.irrigation">{{ item.irrigation }}</span></p>
              <p>土地属性：<span v-tooltip="item.soil">{{ item.soil }}</span></p>
            </div>
          </div>
          <div class="bottom">
            <div>
              <span @click="$router.push({path:'/showProject',query:{id:item.id}})">
                <i class="pi pi-eye"></i> 查看项目</span>
              <span class="p-ml-3" v-if="item.state>3" @click="$router.push({path:'/showSuggest',query:{id:item.id}})"><i class="pi pi-eye"></i> 查看建议</span>
            </div>

            <div>
              <!-- <span style="color: #24bd7f" v-if="userRoleCode==='user'&&(item.state===2||item.state===3)&&item.beginWrite===0"
                @click="$router.push({path:'/suggest',query:{Id:item.id,name:item.name,province:item.province,city:item.city}});">
                <i class="pi pi-pencil"></i> 填写建议
              </span> -->
              <span style="color: #24bd7f" v-if="buttons.txjyBt&&(item.state===2||item.state===3)&&item.beginWrite===0"
                @click="$router.push({path:'/suggest',query:{Id:item.id,name:item.name,province:item.province,city:item.city}});">
                <i class="pi pi-pencil"></i> 填写建议
              </span>
              <span style="color: #24bd7f" v-if="(buttons.bjjyBt&&(item.state===2||item.state===3)&&item.beginWrite==1)||(userRoleCode==='user'&&item.state===4)"
                @click="$router.push({path:'/suggest',query:{Id:item.id,name:item.name,province:item.province,city:item.city}});">
                <i class="pi pi-pencil"></i> 编辑建议
              </span>
              <span style="color: #24bd7f" v-if="buttons.fpzjBt&&item.state===1" @click="$router.push({path:'/distribuirExport',query:{
              id:item.id,name:item.name}})">
                <i class="pi pi-pencil"></i> 分配专家
              </span>
              <span style="color: #24bd7f" v-if="buttons.xgzjBt&&item.state===2" @click="$router.push({path:'/distribuirExport',query:{
              id:item.id,name:item.name}})">
                <i class="pi pi-pencil"></i> 修改专家
              </span>
              <span style="color: #24bd7f" v-if="buttons.bjBt&& item.state<3" @click="$router.push({path:'/createPlan',query:{
              id:item.id}})">
                <i class="pi pi-pencil"></i> 编辑
              </span>
              <span v-if="buttons.drBt&&item.state===4" @click="$router.push({path:'/createPlan',query:{id:item.id}})">导入项目</span>
              <span style="margin-left: 10px;color: #e53a4c" v-if="item.state<5&&buttons.scBt" @click="removePlan(item)">删除</span>
            </div>
          </div>
        </div>
      </div>

      <div class="p-col-12">
        <Paginator :rows="page.size" :totalRecords="page.total" @page="changePage" template=" PrevPageLink PageLinks NextPageLink  CurrentPageReport" :rowsPerPageOptions="[9,18,27,90]"
          currentPageReportTemplate="共{totalRecords} 条记录">
          <template #right>
            <label>跳至&nbsp;&nbsp;</label>
            <InputNumber v-model="page.current" @keydown.enter="getList" v-tooltip.focus="'按下回车键后跳转'" inputStyle="width: 40px;" />
            <label>&nbsp;&nbsp;页</label>
          </template>
        </Paginator>
      </div>
    </div>

  </div>
</template>

<script>
import ShowMap from "@/views/modules/plan/showMap";

export default {
  name: "plan",
  components: { ShowMap },
  data() {
    return {
      list: null,
      userRoleId: null,
      userRoleCode: null,
      page: {
        size: 9,
        total: 0,
        current: 1,
      },
      loading: false,
      state: 0,
      lastGeTime: null,
      buttons: {
        fpzjBt: false,
        xgzjBt: false,
        scBt: false,
        drBt: false,
        txjyBt: false,
        bjjyBt: false,
        bjBt: false,
      },
    };
  },
  beforeUnmount() {
    if (this.map) {
      this.map.destroy();
    }
  },
  mounted() {
    if (localStorage.getItem("menuList")) {
      JSON.parse(localStorage.getItem("menuList")).forEach((item, index) => {
        if (
          item.name == "种植规划" &&
          JSON.parse(localStorage.getItem("menuList"))[index].buttons
        ) {
          JSON.parse(localStorage.getItem("menuList"))[index].buttons.forEach(
            (v) => {
              switch (v.name) {
                case "删除":
                  this.buttons.scBt = true;
                  break;
                case "导入项目":
                  this.buttons.drBt = true;
                  break;
                case "分配专家":
                  this.buttons.fpzjBt = true;
                  break;
                case "修改专家":
                  this.buttons.xgzjBt = true;
                  break;
                case "填写建议":
                  this.buttons.txjyBt = true;
                  break;
                case "编辑建议":
                  this.buttons.bjjyBt = true;
                  break;
                case "编辑":
                  this.buttons.bjBt = true;
                  break;
                default:
                  return;
              }
            }
          );
        }
      });
    }
    if (
      localStorage.getItem("userinfo") &&
      JSON.parse(localStorage.getItem("userinfo")).user.roleId
    ) {
      this.userRoleId = JSON.parse(
        localStorage.getItem("userinfo")
      ).user.roleId;
      this.userRoleCode = JSON.parse(
        localStorage.getItem("userinfo")
      ).user.roleCode;
      if (this.userRoleCode === "ncz") {
        this.page.size = 8;
      }
    }
    this.getList();
  },
  methods: {
    createPlan() {
      this.$router.push("/createPlan");
    },

    getList(type) {
      let now = new Date().getTime();
      if (
        this.lastGeTime == null ||
        (this.lastGeTime && now - this.lastGeTime > 300)
      ) {
        this.loading = true;
        if (type === "search") {
          this.page.current = 1;
        }
        this.$http(
          "/plan/getList",
          "get",
          {
            current: this.page.current,
            size: this.page.size,
            state: this.state > -1 ? this.state : null,
          },
          (res) => {
            console.log(res.data.records)
            this.lastGeTime = now;
            this.list = res.data.records;
            this.page.total = res.data.total;
            this.loading = false;
          }
        );
      }
    },

    changePage(page) {
      this.page.size = page.rows;
      this.page.current = page.page + 1;
      this.getList("page");
    },
    removePlan(item) {
      this.$confirm.require({
        header: "删除",
        message: "确定要删除" + item.name + "吗？",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "确认",
        rejectLabel: "取消",
        accept: () => {
          this.$axios.delete("/plan/delete/" + item.id).then(() => {
            this.$toast.add({
              severity: "success",
              summary: "删除成功",
              life: 3000,
            });
            this.getList();
          });
        },
        reject: () => {
          this.$confirm.close();
        },
      });
    },
  },
};
</script>

<style scoped>
.cardItem {
  border: 1px solid #cccccc;
  height: 200px;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 1rem;
  border-top: 1px solid #cccccc;
  cursor: pointer;
  background-color: #f7f9fa;
}

.content {
  height: calc(100% - 75px);
  padding: 0 1rem 1rem 1rem;
  display: flex;
}

.right {
  width: 50%;
  padding-left: 1rem;
}

.right p {
  margin: 0;
  line-height: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  padding: 0 1rem;
  color: #24bd7f;
}
</style>
